const NumberFireIcon = ({ width = 24, height = 24 }) => (
  <svg
    data-testid="numberFire-icon"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4761_6166)">
      <path
        d="M2.76202 20.7284C1.37155 19.1761 0.514404 17.1856 0.514404 14.9951C0.514404 11.4999 2.50488 8.00464 5.00964 7.00464C5.50488 8.5094 6.26679 10.5189 9.49536 10.4999C6.00012 3.5094 11.4954 0.0141602 12.4954 0.0141602C12.4954 3.28083 14.5715 5.49987 16.4573 7.89987L11.1239 17.4618L7.02869 13.0808L2.76202 20.7284ZM11.4954 20.4904L7.40012 16.1094L4.12393 21.9856C5.81388 23.272 7.87631 23.9739 10.0001 23.9856C14.9906 23.9856 19.4858 19.9856 19.4858 14.9951C19.4858 12.8046 18.6858 11.0618 17.6287 9.49035L11.4954 20.4904Z"
        fill="url(#paint0_linear_4761_6166)"
      />
      <path
        d="M2.76202 20.7284C1.37155 19.1761 0.514404 17.1856 0.514404 14.9951C0.514404 11.4999 2.50488 8.00464 5.00964 7.00464C5.50488 8.5094 6.26679 10.5189 9.49536 10.4999C6.00012 3.5094 11.4954 0.0141602 12.4954 0.0141602C12.4954 3.28083 14.5715 5.49987 16.4573 7.89987L11.1239 17.4618L7.02869 13.0808L2.76202 20.7284ZM11.4954 20.4904L7.40012 16.1094L4.12393 21.9856C5.81388 23.272 7.87631 23.9739 10.0001 23.9856C14.9906 23.9856 19.4858 19.9856 19.4858 14.9951C19.4858 12.8046 18.6858 11.0618 17.6287 9.49035L11.4954 20.4904Z"
        fill="url(#paint1_linear_4761_6166)"
      />
      <path
        d="M6.1525 14.6425L2.76202 20.7282C1.37155 19.1758 0.514404 17.1853 0.514404 14.9949C0.514404 11.4996 2.50488 8.00439 5.00964 7.00439C3.71441 9.15678 3.91441 11.6711 6.1525 14.6425ZM7.40012 16.1092L4.12393 21.9853L4.3525 22.1568C8.60964 25.1377 11.4192 20.4139 7.40012 16.1092Z"
        fill="url(#paint2_linear_4761_6166)"
      />
      <path
        d="M17.6288 9.49035C18.686 11.0618 19.486 12.8046 19.486 14.9951C19.486 19.9856 14.9907 23.9856 10.0002 23.9856C8.63314 23.9804 7.28273 23.685 6.03833 23.1189C9.4669 24.6237 11.0859 23.1094 11.505 20.4713L17.6288 9.49035ZM16.4574 7.89987C14.5717 5.49987 12.4955 3.28083 12.4955 0.0141602C11.4955 0.0141602 6.00024 3.5094 9.49547 10.4999C10.4848 12.4996 11.1503 14.6438 11.4669 16.8523L16.4574 7.89987Z"
        fill="url(#paint3_linear_4761_6166)"
      />
      <path
        d="M17.6287 9.49035C18.6858 11.0618 19.4858 12.8046 19.4858 14.9951C19.4858 19.0046 16.581 22.3761 12.8572 23.5475C16.2858 22.4046 18.6096 17.8427 15.5334 13.2427L17.6287 9.49035ZM14.3525 11.6713L16.4572 7.89987C14.5715 5.49987 12.4953 3.28083 12.4953 0.0141602C11.0572 1.92845 9.762 5.49987 14.3525 11.6713Z"
        fill="url(#paint4_linear_4761_6166)"
      />
      <path
        d="M6.1525 14.6425L2.76202 20.7282C1.37155 19.1758 0.514404 17.1853 0.514404 14.9949C0.514404 11.4996 2.50488 8.00439 5.00964 7.00439C3.71441 9.15678 3.91441 11.6711 6.1525 14.6425ZM7.40012 16.1092L4.12393 21.9853L4.3525 22.1568C8.60964 25.1377 11.4192 20.4139 7.40012 16.1092Z"
        fill="url(#paint5_linear_4761_6166)"
      />
      <path
        d="M17.6288 9.49035C18.686 11.0618 19.486 12.8046 19.486 14.9951C19.486 19.9856 14.9907 23.9856 10.0002 23.9856C8.63314 23.9804 7.28273 23.685 6.03833 23.1189C9.4669 24.6237 11.0859 23.1094 11.505 20.4713L17.6288 9.49035ZM16.4574 7.89987C14.5717 5.49987 12.4955 3.28083 12.4955 0.0141602C11.4955 0.0141602 6.00024 3.5094 9.49547 10.4999C10.4848 12.4996 11.1503 14.6438 11.4669 16.8523L16.4574 7.89987Z"
        fill="url(#paint6_linear_4761_6166)"
      />
      <path
        d="M17.6287 9.49035C18.6858 11.0618 19.4858 12.8046 19.4858 14.9951C19.4858 19.0046 16.581 22.3761 12.8572 23.5475C16.2858 22.4046 18.6096 17.8427 15.5334 13.2427L17.6287 9.49035ZM14.3525 11.6713L16.4572 7.89987C14.5715 5.49987 12.4953 3.28083 12.4953 0.0141602C11.0572 1.92845 9.762 5.49987 14.3525 11.6713Z"
        fill="url(#paint7_linear_4761_6166)"
      />
      <path
        d="M19.4858 6.48059C19.4858 6.85189 19.3383 7.20799 19.0757 7.47054C18.8132 7.73309 18.4571 7.88059 18.0858 7.88059C17.7145 7.88059 17.3584 7.73309 17.0958 7.47054C16.8333 7.20799 16.6858 6.85189 16.6858 6.48059C16.6858 5.48059 17.5906 5.48059 17.0858 3.98535C19.0858 4.49011 19.4858 5.73773 19.4858 6.48059Z"
        fill="url(#paint8_linear_4761_6166)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_4761_6166"
        x1="12.3973"
        y1="11.9789"
        x2="2.91822"
        y2="17.3389"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.18" stopColor="#FFE187" />
        <stop offset="0.24" stopColor="#FFD986" />
        <stop offset="0.33" stopColor="#FFC384" />
        <stop offset="0.44" stopColor="#FFA081" />
        <stop offset="0.55" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4761_6166"
        x1="18.2668"
        y1="13.3104"
        x2="7.55345"
        y2="11.4218"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.24" stopColor="#FFE187" />
        <stop offset="0.28" stopColor="#FFE187" stopOpacity="0.95" />
        <stop offset="0.36" stopColor="#FFE187" stopOpacity="0.83" />
        <stop offset="0.46" stopColor="#FFE187" stopOpacity="0.62" />
        <stop offset="0.58" stopColor="#FFE187" stopOpacity="0.34" />
        <stop offset="0.72" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4761_6166"
        x1="4.92679"
        y1="7.0063"
        x2="4.92679"
        y2="23.0911"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.14" stopColor="#FFE187" />
        <stop offset="0.35" stopColor="#FFC084" />
        <stop offset="0.78" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4761_6166"
        x1="11.125"
        y1="0.133208"
        x2="13.1907"
        y2="23.7418"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.25" stopColor="#FFE187" />
        <stop offset="0.33" stopColor="#FFD286" />
        <stop offset="0.67" stopColor="#FF8E7F" />
        <stop offset="0.83" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4761_6166"
        x1="18.4401"
        y1="10.1284"
        x2="9.37343"
        y2="12.7284"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.04" stopColor="#FFE187" />
        <stop offset="0.13" stopColor="#FFD686" />
        <stop offset="0.29" stopColor="#FFBA83" />
        <stop offset="0.49" stopColor="#FF8B7F" />
        <stop offset="0.59" stopColor="#FF737D" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4761_6166"
        x1="8.40869"
        y1="13.9844"
        x2="0.875358"
        y2="17.6587"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE187" />
        <stop offset="0.03" stopColor="#FFE187" stopOpacity="0.92" />
        <stop offset="0.12" stopColor="#FFE187" stopOpacity="0.71" />
        <stop offset="0.21" stopColor="#FFE187" stopOpacity="0.52" />
        <stop offset="0.31" stopColor="#FFE187" stopOpacity="0.36" />
        <stop offset="0.4" stopColor="#FFE187" stopOpacity="0.23" />
        <stop offset="0.5" stopColor="#FFE187" stopOpacity="0.13" />
        <stop offset="0.6" stopColor="#FFE187" stopOpacity="0.06" />
        <stop offset="0.71" stopColor="#FFE187" stopOpacity="0.01" />
        <stop offset="0.83" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_4761_6166"
        x1="18.8688"
        y1="10.7246"
        x2="4.3069"
        y2="13.2923"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFE187" />
        <stop offset="0.15" stopColor="#FFE187" stopOpacity="0.8" />
        <stop offset="0.19" stopColor="#FFE187" stopOpacity="0.62" />
        <stop offset="0.23" stopColor="#FFE187" stopOpacity="0.45" />
        <stop offset="0.27" stopColor="#FFE187" stopOpacity="0.31" />
        <stop offset="0.32" stopColor="#FFE187" stopOpacity="0.2" />
        <stop offset="0.37" stopColor="#FFE187" stopOpacity="0.11" />
        <stop offset="0.42" stopColor="#FFE187" stopOpacity="0.05" />
        <stop offset="0.48" stopColor="#FFE187" stopOpacity="0.01" />
        <stop offset="0.57" stopColor="#FFE187" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_4761_6166"
        x1="17.582"
        y1="21.6151"
        x2="12.5744"
        y2="10.0989"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.41" stopColor="#FF737D" />
        <stop offset="0.46" stopColor="#FF737D" stopOpacity="0.95" />
        <stop offset="0.54" stopColor="#FF737D" stopOpacity="0.83" />
        <stop offset="0.65" stopColor="#FF737D" stopOpacity="0.62" />
        <stop offset="0.79" stopColor="#FF737D" stopOpacity="0.34" />
        <stop offset="0.93" stopColor="#FF737D" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_4761_6166"
        x1="16.4325"
        y1="4.6444"
        x2="19.942"
        y2="8.15392"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.11" stopColor="#FFE187" />
        <stop offset="0.35" stopColor="#FFC084" />
        <stop offset="0.84" stopColor="#FF737D" />
      </linearGradient>
      <clipPath id="clip0_4761_6166">
        <rect
          width="18.9714"
          height="23.9714"
          fill="white"
          transform="translate(0.514404 0.0141602)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default NumberFireIcon;
