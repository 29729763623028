import { keepPreviousData, useQuery } from "@tanstack/react-query";
import React from "react";
import { getAvailableSports } from "../api";
import { filterEmpty } from "..";

const useSports = () => {
  const { data, isLoading, isError } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: ["available-sports"],
    queryFn: () => getAvailableSports(),
    select: (resp) => resp.getSupportedSports,
  });

  const selectableSportsWithScores = React.useMemo(() => {
    const sportLabels = filterEmpty(data || [])
      .filter((sport) => sport.hasLiveScoring)
      .map((sport) => sport?.name);
    return ["All", ...sportLabels];
  }, [data]);

  const selectableSportsWithUpdates = React.useMemo(() => {
    const sportLabels = filterEmpty(data || [])
      .filter((sport) => sport.hasPlayerUpdates)
      .map((sport) => sport?.name);
    return ["All", ...sportLabels];
  }, [data]);

  const selectableSportsForAuthors = React.useMemo(() => {
    const sportLabels = filterEmpty(data || [])
      .filter((sport) => sport.showAuthors)
      .map((sport) => ({
        name: sport?.name,
        contentSport: sport.contentSport,
      }));
    return [{ name: "All" }, ...sportLabels];
  }, [data]);

  const selectableSports = React.useMemo(() => {
    const sportLabels = filterEmpty(data || []).map((sport) => sport?.name);
    return ["All", ...sportLabels];
  }, [data]);

  return {
    sports: data,
    isLoading,
    isError,
    selectableSportsWithScores,
    selectableSportsWithUpdates,
    selectableSportsForAuthors,
    selectableSports,
  };
};

export default useSports;
