import { useEffect, useCallback, useState, useRef } from "react";
import { ScrollItem } from "@/components/common";
import ErrorComponent from "@/components/empty/ErrorComponent";
import useGetScores from "@/utils/hooks/useGetScores";
import { filterEmpty } from "@/utils/index";
import useSports from "@/utils/hooks/useSports";
import useScrollRefs from "@/utils/hooks/useScrollRefs";
import { Card, Dropdown, Icon } from "@fanduel/og-fe-library";
import GameCard from "./GameCard";
import SadCalendar from "../svg/SadCalendar";
import TabsPagination from "@/components/TabsPagination";
import TabsLoadMore from "@/components/TabsLoadMore";
import LoadingBackdrop from "../LoadingBackdrop";
import styles from "./scoreboard.module.scss";

interface ScoreBoardContainerProps {
  sport?: string;
}

const ScoreBoardContainer = ({ sport }: ScoreBoardContainerProps) => {
  const [allActive, setAllActive] = useState(false);
  const mobileGameCt = 4;
  const offsetPixels = 378; // width of GameCard + padding
  const { selectedSport, setSport, scores, isLoading } = useGetScores(sport);
  const {
    containerRef,
    scrollRef,
    addOffset,
    removeOffset,
    disableScrollPlus,
    disableScrollMinus,
    scrollUpdate,
  } = useScrollRefs(offsetPixels, scores);
  const { selectableSportsWithScores } = useSports();
  const filteredGames = filterEmpty(scores ?? []);
  const [sportIndex, setSportIndex] = useState(0);
  const sportRef = useRef("");

  useEffect(() => {
    if (sport && sportRef.current !== sport) {
      setSport(sport);
      const index = selectableSportsWithScores?.indexOf(sport) ?? 0;
      setSportIndex(index !== -1 ? index : 0);
      sportRef.current = sport;
    }
    if (containerRef.current?.scrollLeft) {
      containerRef.current.scrollLeft = 0;
    }
  }, [
    selectedSport,
    containerRef,
    sport,
    setSport,
    selectableSportsWithScores,
  ]);

  const contentRender = useCallback(() => {
    if (isLoading) {
      return (
        <div className={styles.statusContainer}>
          <LoadingBackdrop loading={isLoading} />
        </div>
      );
    }

    if (filteredGames.length === 0) {
      return (
        <div className={styles.statusContainer}>
          <ErrorComponent
            image={<SadCalendar />}
            headline="Looks like the field is empty."
            subtext="No upcoming games scheduled."
            showCta={false}
          />
        </div>
      );
    }

    return (
      <div
        className={styles.itemContainer}
        onScroll={scrollUpdate}
        ref={containerRef}
      >
        <div className={styles.scrollContainer}>
          {filteredGames.map((score, index) => (
            <ScrollItem key={score.id} isHidden={!allActive && index > 3}>
              <GameCard {...score} />
            </ScrollItem>
          ))}
          <div className={styles.lastItem} ref={scrollRef} />
        </div>
      </div>
    );
  }, [
    isLoading,
    filteredGames,
    scrollRef,
    allActive,
    containerRef,
    scrollUpdate,
  ]);

  const loadMoreRender = useCallback(() => {
    if (filteredGames.length <= mobileGameCt) {
      return null;
    }

    return (
      <div className={styles.mobileToggle}>
        <TabsLoadMore
          allActive={allActive}
          showLessLabel="Show less games"
          showMoreLabel="Show all games"
          onClick={() => setAllActive((val) => !val)}
        />
      </div>
    );
  }, [allActive, filteredGames.length]);

  const setSelectedSport = (sportIndexArg: number) => {
    const sportSelection = selectableSportsWithScores?.[sportIndexArg];
    setSport(sportSelection);
  };

  return (
    <Card variant="elevation" rounded="sm">
      <div className={styles.header}>
        <h3 className={styles.title}>
          <Icon
            iconName="icons.calendar"
            iconSize={17}
            iconColor="colorBaseBlack"
          />
          Upcoming Games and Scoreboard
        </h3>
        <TabsPagination
          addOffset={addOffset}
          removeOffset={removeOffset}
          disableScrollPlus={disableScrollPlus}
          disableScrollMinus={disableScrollMinus}
        />
      </div>
      <div className={styles.dropDownContainer}>
        <Dropdown
          options={selectableSportsWithScores.map((sportLabel) => ({
            label: sportLabel,
          }))}
          selected={sportIndex}
          title="LEAGUE"
          setSelected={setSportIndex}
          onChange={setSelectedSport}
        />
      </div>
      {contentRender()}
      {loadMoreRender()}
    </Card>
  );
};

export default ScoreBoardContainer;
