import {
  ArrowsChevronUp,
  ArrowsChevronDown,
} from "@fanduel/formation-react-icons";
import styles from "./TabsLoadMore.module.scss";

type TabsLoadMoreProps = {
  allActive: boolean;
  showLessLabel: string;
  showMoreLabel: string;
  onClick: () => void;
};

const TabsLoadMore = ({
  allActive,
  showLessLabel,
  showMoreLabel,
  onClick,
}: TabsLoadMoreProps) => (
  <button
    type="button"
    aria-label={allActive ? showLessLabel : showMoreLabel}
    onClick={onClick}
    className={styles.button}
  >
    {allActive ? (
      <div className={styles.buttonLabel}>
        {showLessLabel}
        <ArrowsChevronUp />
      </div>
    ) : (
      <div className={styles.buttonLabel}>
        {showMoreLabel}
        <ArrowsChevronDown />
      </div>
    )}
  </button>
);

export default TabsLoadMore;
