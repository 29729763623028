import "twin.macro";
import { Loader } from "@fanduel/og-fe-library";

type LoadingBackdropProps = {
  loading: boolean;
};

const LoadingBackdrop = ({ loading }: LoadingBackdropProps) => {
  if (loading) {
    return (
      <div
        tw="flex h-full w-full grow items-center justify-center"
        data-testid="loading-backdrop"
      >
        <Loader size="medium" />
      </div>
    );
  }

  return null;
};

export default LoadingBackdrop;
