import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { ALL } from "@/constants/sports";
import { getScores } from "../api";
import { GetActiveFixturesQuery } from "@/graphql/gql/graphql";

const useGetScores = (sport = "All") => {
  const [selectedSport, setSport] = useState<string>(sport);
  const sportArg = selectedSport === ALL ? undefined : selectedSport;
  const { data, isLoading, isError } = useQuery<GetActiveFixturesQuery>({
    queryKey: ["scores", selectedSport, sportArg],
    queryFn: () => getScores(sportArg),
    refetchInterval: 30000,
  });

  return {
    scores: data?.getActiveFixtures,
    isLoading,
    isError,
    selectedSport,
    setSport,
  };
};

export default useGetScores;
