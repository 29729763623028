import { GameStatus } from "@/graphql/gql/graphql";
import { FixtureTeamSummary } from "@/types/scoreBoard";

type GameCardTeamSummary = {
  currentScore: number | null;
  moneyLine: string;
  pointSpread: string;
  fixtureMoneyLine: { odds: string; link: string };
  fixtureSpread: { odds: string; value: string; link: string };
  fixtureTotal: { odds: string; value: string; link: string };
  imgAlt: string;
  imgSrc: string;
  abbreviation: string;
  primaryColor: string;
  record: string;
  winProbability: number;
  sportsbookLink: string;
};

type WinProbabilities = {
  hWinProbability: number;
  aWinProbability: number;
};

export const getRecord = (
  winCount: number,
  lossCount: number,
  drawCount: number
) => {
  if (drawCount) {
    return `${winCount}-${lossCount}-${drawCount}`;
  }
  return `${winCount}-${lossCount}`;
};

export const formatOdds = (odds?: number | null) => {
  if (odds === null || odds === undefined) {
    return "";
  }
  return odds > 0 ? `+${odds}` : `${odds}`;
};

export const parseWinProbability = (
  gameStatus: GameStatus,
  aWinProbability: number,
  hWinProbability: number
) => {
  const result: WinProbabilities = {
    aWinProbability: 0,
    hWinProbability: 0,
  };

  if (gameStatus !== GameStatus.Scheduled) {
    return result;
  }

  if (aWinProbability > hWinProbability) {
    result.aWinProbability = aWinProbability;
  } else {
    result.hWinProbability = hWinProbability;
  }

  return result;
};

export const parseTeamSummary = (teamArg: FixtureTeamSummary) => {
  const result: GameCardTeamSummary = {
    currentScore: null,
    pointSpread: "",
    moneyLine: "",
    fixtureMoneyLine: { odds: "", link: "" },
    fixtureSpread: { odds: "", value: "", link: "" },
    fixtureTotal: { odds: "", value: "", link: "" },
    imgAlt: "",
    imgSrc: "",
    abbreviation: "",
    primaryColor: "",
    record: "",
    winProbability: 0,
    sportsbookLink: "",
  };

  if (!teamArg) {
    return result;
  }

  const {
    currentScore,
    pointSpread,
    moneyLine,
    fixtureSpread,
    fixtureMoneyLine,
    fixtureTotal,
    winProbability,
    team: {
      name,
      image,
      abbreviation,
      primaryColor,
      winCount,
      loseCount,
      drawCount,
      sportsbookLink,
    },
  } = teamArg;

  const displayPointSpread = formatOdds(pointSpread);
  const displayMoneyLine = formatOdds(moneyLine);

  result.currentScore = currentScore || null;
  result.pointSpread = displayPointSpread;
  result.moneyLine = displayMoneyLine;
  if (fixtureMoneyLine != null) {
    const { link, odds } = fixtureMoneyLine;
    result.fixtureMoneyLine = { link: link ?? "", odds: formatOdds(odds) };
  }
  if (fixtureSpread != null) {
    const { link, value, odds } = fixtureSpread;
    result.fixtureSpread = {
      link: link ?? "",
      value: value?.toString() ?? "",
      odds: formatOdds(odds),
    };
  }
  if (fixtureTotal != null) {
    const { link, value, odds } = fixtureTotal;
    result.fixtureTotal = {
      link: link ?? "",
      value: value?.toString() ?? "",
      odds: formatOdds(odds),
    };
  }
  result.imgAlt = `${name || ""} logo`;
  result.imgSrc = image?.url || "";
  result.abbreviation = abbreviation || "";
  result.primaryColor = primaryColor || "";
  result.record = getRecord(winCount || 0, loseCount || 0, drawCount || 0);
  result.winProbability = winProbability ?? 0;
  result.sportsbookLink = sportsbookLink || "";

  return result;
};
