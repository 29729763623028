import "twin.macro";
import styled from "styled-components";
import Svg from "./Svg";

const Icon = styled(Svg)` 
  height: 133;
  width: 149;
  margin-top: 5px
  fill-rule: evenodd; 
  clip-rule: evenodd;
  fill: none

`;

function SadCalendar({ color }: { color?: string }) {
  return (
    <Icon tw="pt-1" color={color}>
      <svg viewBox="0 0 133 149" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M129.769 6.29199H3.08778C1.69178 6.29199 0.550781 7.43399 0.550781 8.82899V25.149V33.937V36.474V145.59C0.550781 146.986 1.69278 148.127 3.08778 148.127H129.769C131.165 148.127 132.306 146.985 132.306 145.59V36.475V33.938V25.15V8.82999C132.306 7.43399 131.165 6.29199 129.769 6.29199Z"
          fill="#DAE5F0"
        />
        <path
          d="M99.247 97.9159C88.534 92.5169 80.81 97.7869 80.488 98.0149C80.129 98.2669 79.917 98.6789 79.921 99.1179C80.014 110.851 88.762 115.237 89.135 115.418C89.318 115.508 89.518 115.552 89.717 115.552C89.947 115.552 90.179 115.493 90.385 115.374C100.538 109.503 100.007 99.4459 99.978 99.0209C99.947 98.5479 99.669 98.1279 99.247 97.9159ZM89.695 112.673C87.928 111.573 82.965 107.792 82.607 99.8459C83.185 99.5309 84.17 99.0719 85.484 98.7119C85.462 98.8809 85.471 99.0569 85.519 99.2319C85.781 100.192 88.027 107.985 92.249 110.703C91.506 111.392 90.669 112.057 89.695 112.673ZM94.0819 108.688C91.0679 107.247 88.749 100.931 88.091 98.5289C88.062 98.4239 88.001 98.3409 87.95 98.2489C90.465 97.9859 93.659 98.2639 97.31 99.9419C97.24 101.488 96.7619 105.176 94.0819 108.688Z"
          fill="#C6D4E4"
        />
        <path
          d="M52.8554 72.7839C42.1424 67.3869 34.4204 72.6549 34.0964 72.8829C33.7374 73.1349 33.5254 73.5469 33.5294 73.9859C33.6224 85.7189 42.3693 90.1029 42.7423 90.2839C42.9253 90.3739 43.1254 90.4179 43.3244 90.4179C43.5544 90.4179 43.7853 90.3589 43.9923 90.2399C54.1463 84.3699 53.6154 74.3129 53.5864 73.8879C53.5554 73.4159 53.2774 72.9959 52.8554 72.7839ZM43.3024 87.5399C41.5354 86.4409 36.5723 82.6609 36.2143 74.7139C36.7914 74.3989 37.7774 73.9399 39.0914 73.5789C39.0694 73.7479 39.0784 73.9239 39.1264 74.0979C39.3884 75.0589 41.6354 82.8589 45.8604 85.5739C45.1174 86.2599 44.2794 86.9239 43.3024 87.5399ZM47.7034 83.5629C44.6834 82.1349 42.3584 75.8029 41.6994 73.3969C41.6704 73.2919 41.6094 73.2079 41.5584 73.1169C44.0744 72.8539 47.2704 73.1319 50.9234 74.8129C50.8644 76.3699 50.4034 80.0489 47.7034 83.5629Z"
          fill="#C6D4E4"
        />
        <path
          d="M89.135 67.202C89.318 67.292 89.518 67.336 89.717 67.336C89.947 67.336 90.179 67.277 90.385 67.158C100.538 61.287 100.007 51.23 99.978 50.805C99.947 50.333 99.669 49.913 99.247 49.701C88.534 44.303 80.81 49.571 80.488 49.8C80.129 50.052 79.917 50.464 79.921 50.903C80.013 62.636 88.762 67.022 89.135 67.202ZM97.31 51.728C97.24 53.274 96.7619 56.962 94.0819 60.473C91.0679 59.033 88.749 52.717 88.091 50.314C88.062 50.209 88.001 50.126 87.951 50.035C90.466 49.773 93.66 50.05 97.31 51.728ZM85.484 50.498C85.462 50.667 85.471 50.843 85.519 51.017C85.781 51.977 88.027 59.771 92.249 62.488C91.507 63.177 90.67 63.842 89.696 64.458C87.929 63.358 82.966 59.577 82.608 51.631C83.184 51.317 84.17 50.858 85.484 50.498Z"
          fill="#C6D4E4"
        />
        <path
          d="M25.4806 64.319H12.9336C11.8336 64.319 10.9336 63.419 10.9336 62.319V49.772C10.9336 48.672 11.8336 47.772 12.9336 47.772H25.4806C26.5806 47.772 27.4806 48.672 27.4806 49.772V62.319C27.4806 63.419 26.5806 64.319 25.4806 64.319Z"
          fill="#EEF4F9"
        />
        <path
          d="M49.588 64.319H37.041C35.941 64.319 35.041 63.419 35.041 62.319V49.772C35.041 48.672 35.941 47.772 37.041 47.772H49.588C50.688 47.772 51.588 48.672 51.588 49.772V62.319C51.588 63.419 50.688 64.319 49.588 64.319Z"
          fill="#EEF4F9"
        />
        <path
          d="M72.7286 64.319H60.1816C59.0816 64.319 58.1816 63.419 58.1816 62.319V49.772C58.1816 48.672 59.0816 47.772 60.1816 47.772H72.7286C73.8286 47.772 74.7286 48.672 74.7286 49.772V62.319C74.7286 63.419 73.8286 64.319 72.7286 64.319Z"
          fill="#EEF4F9"
        />
        <path
          d="M119.675 64.319H107.128C106.028 64.319 105.128 63.419 105.128 62.319V49.772C105.128 48.672 106.028 47.772 107.128 47.772H119.675C120.775 47.772 121.675 48.672 121.675 49.772V62.319C121.675 63.419 120.775 64.319 119.675 64.319Z"
          fill="#EEF4F9"
        />
        <path
          d="M25.4806 88.4259H12.9336C11.8336 88.4259 10.9336 87.5259 10.9336 86.4259V73.8789C10.9336 72.7789 11.8336 71.8789 12.9336 71.8789H25.4806C26.5806 71.8789 27.4806 72.7789 27.4806 73.8789V86.4259C27.4806 87.5259 26.5806 88.4259 25.4806 88.4259Z"
          fill="#EEF4F9"
        />
        <path
          d="M72.7286 88.4259H60.1816C59.0816 88.4259 58.1816 87.5259 58.1816 86.4259V73.8789C58.1816 72.7789 59.0816 71.8789 60.1816 71.8789H72.7286C73.8286 71.8789 74.7286 72.7789 74.7286 73.8789V86.4259C74.7286 87.5259 73.8286 88.4259 72.7286 88.4259Z"
          fill="#EEF4F9"
        />
        <path
          d="M96.8361 88.4259H84.2891C83.1891 88.4259 82.2891 87.5259 82.2891 86.4259V73.8789C82.2891 72.7789 83.1891 71.8789 84.2891 71.8789H96.8361C97.9361 71.8789 98.8361 72.7789 98.8361 73.8789V86.4259C98.8361 87.5259 97.9361 88.4259 96.8361 88.4259Z"
          fill="#EEF4F9"
        />
        <path
          d="M119.675 88.4259H107.128C106.028 88.4259 105.128 87.5259 105.128 86.4259V73.8789C105.128 72.7789 106.028 71.8789 107.128 71.8789H119.675C120.775 71.8789 121.675 72.7789 121.675 73.8789V86.4259C121.675 87.5259 120.775 88.4259 119.675 88.4259Z"
          fill="#EEF4F9"
        />
        <path
          d="M25.4806 113.497H12.9336C11.8336 113.497 10.9336 112.597 10.9336 111.497V98.95C10.9336 97.85 11.8336 96.95 12.9336 96.95H25.4806C26.5806 96.95 27.4806 97.85 27.4806 98.95V111.497C27.4806 112.597 26.5806 113.497 25.4806 113.497Z"
          fill="#EEF4F9"
        />
        <path
          d="M49.588 113.497H37.041C35.941 113.497 35.041 112.597 35.041 111.497V98.95C35.041 97.85 35.941 96.95 37.041 96.95H49.588C50.688 96.95 51.588 97.85 51.588 98.95V111.497C51.588 112.597 50.688 113.497 49.588 113.497Z"
          fill="#EEF4F9"
        />
        <path
          d="M72.7286 113.497H60.1816C59.0816 113.497 58.1816 112.597 58.1816 111.497V98.95C58.1816 97.85 59.0816 96.95 60.1816 96.95H72.7286C73.8286 96.95 74.7286 97.85 74.7286 98.95V111.497C74.7286 112.597 73.8286 113.497 72.7286 113.497Z"
          fill="#EEF4F9"
        />
        <path
          d="M119.675 113.497H107.128C106.028 113.497 105.128 112.597 105.128 111.497V98.95C105.128 97.85 106.028 96.95 107.128 96.95H119.675C120.775 96.95 121.675 97.85 121.675 98.95V111.497C121.675 112.597 120.775 113.497 119.675 113.497Z"
          fill="#EEF4F9"
        />
        <path
          d="M25.4806 137.91H12.9336C11.8336 137.91 10.9336 137.01 10.9336 135.91V123.363C10.9336 122.263 11.8336 121.363 12.9336 121.363H25.4806C26.5806 121.363 27.4806 122.263 27.4806 123.363V135.91C27.4806 137.01 26.5806 137.91 25.4806 137.91Z"
          fill="#EEF4F9"
        />
        <path
          d="M49.588 137.91H37.041C35.941 137.91 35.041 137.01 35.041 135.91V123.363C35.041 122.263 35.941 121.363 37.041 121.363H49.588C50.688 121.363 51.588 122.263 51.588 123.363V135.91C51.588 137.01 50.688 137.91 49.588 137.91Z"
          fill="#EEF4F9"
        />
        <path
          d="M72.7286 137.91H60.1816C59.0816 137.91 58.1816 137.01 58.1816 135.91V123.363C58.1816 122.263 59.0816 121.363 60.1816 121.363H72.7286C73.8286 121.363 74.7286 122.263 74.7286 123.363V135.91C74.7286 137.01 73.8286 137.91 72.7286 137.91Z"
          fill="#EEF4F9"
        />
        <path
          d="M96.8361 137.91H84.2891C83.1891 137.91 82.2891 137.01 82.2891 135.91V123.363C82.2891 122.263 83.1891 121.363 84.2891 121.363H96.8361C97.9361 121.363 98.8361 122.263 98.8361 123.363V135.91C98.8361 137.01 97.9361 137.91 96.8361 137.91Z"
          fill="#EEF4F9"
        />
        <path
          d="M119.675 137.91H107.128C106.028 137.91 105.128 137.01 105.128 135.91V123.363C105.128 122.263 106.028 121.363 107.128 121.363H119.675C120.775 121.363 121.675 122.263 121.675 123.363V135.91C121.675 137.01 120.775 137.91 119.675 137.91Z"
          fill="#EEF4F9"
        />
        <path
          d="M129.769 6.29199H3.08778C1.69178 6.29199 0.550781 7.43399 0.550781 8.82899V25.149V33.937V36.474H3.08778H129.769H132.306V33.937V25.149V8.82999C132.306 7.43399 131.165 6.29199 129.769 6.29199Z"
          fill="#C6D4E4"
        />
        <path
          d="M115.022 26.3049H111.778C110.678 26.3049 109.778 25.4049 109.778 24.3049V2.89795C109.778 1.79795 110.678 0.897949 111.778 0.897949H115.022C116.122 0.897949 117.022 1.79795 117.022 2.89795V24.3049C117.022 25.4049 116.122 26.3049 115.022 26.3049Z"
          fill="#B3C6DA"
        />
        <path
          d="M21.4784 26.3049H18.2344C17.1344 26.3049 16.2344 25.4049 16.2344 24.3049V2.89795C16.2344 1.79795 17.1344 0.897949 18.2344 0.897949H21.4784C22.5784 0.897949 23.4784 1.79795 23.4784 2.89795V24.3049C23.4784 25.4049 22.5784 26.3049 21.4784 26.3049Z"
          fill="#B3C6DA"
        />
        <path
          d="M51.2894 16.451L48.6414 25.71C48.4284 26.438 47.7534 26.954 47.0064 26.954H46.9714C46.1894 26.954 45.5314 26.439 45.3534 25.71L43.5594 18.709L41.7814 25.71C41.6044 26.438 40.9114 26.954 40.1824 26.954H40.1474C39.3834 26.954 38.7074 26.439 38.4944 25.71L35.8284 16.434C35.7934 16.327 35.7754 16.202 35.7754 16.096C35.7754 15.421 36.3264 14.781 37.1254 14.781C37.7304 14.781 38.2814 15.19 38.4414 15.812L40.3244 23.559L42.3504 15.812C42.4924 15.278 42.9914 14.888 43.5944 14.888C44.1454 14.888 44.6254 15.278 44.7674 15.812L46.7934 23.559L48.6424 15.829C48.7844 15.207 49.3534 14.781 49.9924 14.781C50.7924 14.781 51.3424 15.403 51.3424 16.096C51.3434 16.219 51.3254 16.344 51.2894 16.451Z"
          fill="#B3C6DA"
        />
        <path
          d="M60.6463 26.847H54.6573C53.8753 26.847 53.2363 26.208 53.2363 25.426V16.415C53.2363 15.633 53.8753 14.994 54.6573 14.994H60.6463C61.2323 14.994 61.7303 15.491 61.7303 16.113C61.7303 16.718 61.2323 17.216 60.6463 17.216H55.7583V19.721H60.5223C61.1083 19.721 61.6063 20.219 61.6063 20.841C61.6063 21.444 61.1083 21.942 60.5223 21.942H55.7583V24.626H60.6463C61.2323 24.626 61.7303 25.124 61.7303 25.71C61.7303 26.349 61.2323 26.847 60.6463 26.847Z"
          fill="#B3C6DA"
        />
        <path
          d="M71.3553 26.847H65.3663C64.5843 26.847 63.9453 26.208 63.9453 25.426V16.415C63.9453 15.633 64.5843 14.994 65.3663 14.994H71.3553C71.9413 14.994 72.4393 15.491 72.4393 16.113C72.4393 16.718 71.9413 17.216 71.3553 17.216H66.4673V19.721H71.2313C71.8173 19.721 72.3153 20.219 72.3153 20.841C72.3153 21.444 71.8173 21.942 71.2313 21.942H66.4673V24.626H71.3553C71.9413 24.626 72.4393 25.124 72.4393 25.71C72.4393 26.349 71.9413 26.847 71.3553 26.847Z"
          fill="#B3C6DA"
        />
        <path
          d="M82.3683 26.9531C81.9953 26.9531 81.6392 26.7931 81.4082 26.4741L78.1032 22.1021L77.1803 23.2051V25.7101C77.1803 26.3851 76.6103 26.9541 75.9003 26.9541C75.2253 26.9541 74.6562 26.3851 74.6562 25.7101V16.1321C74.6562 15.4571 75.2252 14.8881 75.9352 14.8881C76.6102 14.8881 77.1803 15.4571 77.1803 16.1321V20.2901L81.0892 15.3501C81.3022 15.0651 81.6582 14.9231 81.9962 14.9231C82.6352 14.9231 83.2043 15.4381 83.2043 16.0791C83.2043 16.3451 83.1152 16.6121 82.9382 16.8251L79.7753 20.5571L83.3473 25.0181C83.5073 25.2311 83.5963 25.4801 83.5963 25.7281C83.5953 26.3491 83.0613 26.9531 82.3683 26.9531Z"
          fill="#B3C6DA"
        />
        <path
          d="M94.8893 26.953C94.2143 26.953 93.6453 26.384 93.6453 25.709V18.246L92.3123 19.614C92.1163 19.81 91.8673 19.899 91.6193 19.899C91.0683 19.899 90.5703 19.437 90.5703 18.85C90.5703 18.584 90.6773 18.317 90.8903 18.104L93.6273 15.331C93.8953 15.065 94.2853 14.905 94.6943 14.905C95.4943 14.905 96.1703 15.544 96.1703 16.345V25.71C96.1693 26.385 95.6003 26.953 94.8893 26.953Z"
          fill="#B3C6DA"
        />
      </svg>
    </Icon>
  );
}
export default SadCalendar;
